body {
  margin: 0;
  font-family: "Roboto", "Actor", "Segoe UI", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: "Courier New", monospace;
}

.reportheader {
  font-weight: "bold";
}

.bg {
  background-image: url("../assets/images/Bg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
